import React from "react";

function Phone() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <g data-name="Group 23" transform="translate(-1725 -2006)">
        <circle
          cx="12.5"
          cy="12.5"
          r="12.5"
          data-name="Ellipse 26"
          transform="translate(1725 2006)"
        ></circle>
        <path
          fill="#fff"
          d="M7.906 0H1.4A1.4 1.4 0 000 1.4v12.088a1.4 1.4 0 001.4 1.4h6.506a1.4 1.4 0 001.4-1.4V1.4a1.4 1.4 0 00-1.4-1.4zM4.651 13.953a.93.93 0 11.93-.93.929.929 0 01-.93.93zm3.256-3.139a.35.35 0 01-.349.349H1.744a.35.35 0 01-.349-.349v-9.07a.35.35 0 01.349-.344h5.814a.35.35 0 01.349.349z"
          transform="translate(1733 2011)"
        ></path>
      </g>
    </svg>
  );
}

export default Phone;
