import React, {FunctionComponent} from "react";
import {SubHeader} from "../components/typography/typography";
import {EventsContent} from "../domain/content-response";
import PageProps from "../domain/page-props";
import Event from '../components/events/event';

const Events: FunctionComponent<PageProps<EventsContent>> = ({content}) => {

  const {eventsHeadline, eventsDescription, events} = content;

  return <>
    <div className="container mb-5">
      <div className="row">
        <div className="col-md-12 animate__animated animate__fadeIn">
          <SubHeader>{eventsHeadline}</SubHeader>
          <div dangerouslySetInnerHTML={{__html: eventsDescription}}/>
        </div>
      </div>
    </div>

    {events.map((event, index) => <Event key={index} event={event} />)}
  </>;
}

export default Events;