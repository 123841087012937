import React, {FunctionComponent} from 'react';
import styled from 'styled-components';
import Navigation from "../navigation/navigation";
import Footer from "./footer";
import ContactFooter from './contact-footer';

const LayoutContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-position: left top;
  background-size: cover;
  background-repeat: no-repeat;
  height: 292px;
  
  @media all and (min-width: 2000px) {
    height: 420px;  
  }
  
  z-index: -1;  
`;

const Container = styled.div`
  padding: 150px 0 0;
`;

const Layout: FunctionComponent = ({children}) => {
  return <>
    <LayoutContainer style={{backgroundImage: `url("/swirls.png")`}} />
    <Navigation />
    <Container>
      {children}
    </Container>
    <ContactFooter />
    <Footer />
  </>;
}

export default Layout;