import React, {FunctionComponent, useContext, useRef, useEffect} from 'react';
import {ContentContext, ContentContextState, NavigationItem} from "../../app";
import {Link, useLocation} from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  font-size: 20px;
  font-family: Staatliches, cursive;
  color: ${({ theme }) => theme.black};
  opacity: .8;
  transition: opacity .5s;
  
  &:hover {    
    color: ${({ theme }) => theme.black};
    text-decoration: none;
    opacity: 1;
  }
`;

const NavigationContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  z-index: 100;
  transition: all 0.5s ease;
`;

const LinkContainer = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  padding: 16px;
`;

const BottomLinkContainer = styled(LinkContainer)`  
  padding-top: 6px;
  
  a {
    font-size: 16px;
    color: ${({theme}) => theme.signature};
    padding: 0;
    margin-top: -16px;
    margin-left: 16px;
  }
  
`;

const NavigationLink: FunctionComponent<NavigationItem> = ({ url, text }) => {
  return <StyledLink to={url} style={{marginLeft: '16px'}}>{text}</StyledLink>;
}

const Navigation: FunctionComponent = () => {
  const containerRef = useRef<HTMLDivElement>();
  const {topLevelNavigation, subLevelNavigation} = useContext<ContentContextState>(ContentContext);
  const location = useLocation();
  const path = `${location.pathname}/`.replace('//', '/');
  const subItemKey = Object.keys(subLevelNavigation)
    .find((networkPath) => path.indexOf(networkPath) > -1);

  useEffect(() => {
    const scrollHandler = () => {
      if (window.scrollY > 135) {
        containerRef.current.style.setProperty('background-color', 'white');
        containerRef.current.style.setProperty('box-shadow', '0px 3px 15px rgba(0,0,0,0.2)');
      } else {
        containerRef.current.style.setProperty('background-color', 'transparent');
        containerRef.current.style.setProperty('box-shadow', 'none');
      }
    }

    document.addEventListener('scroll', scrollHandler);

    return () => document.removeEventListener('scroll', scrollHandler);
  }, []);

  return <NavigationContainer ref={containerRef} className="container-fluid">
    <LinkContainer>
      {topLevelNavigation.map((item) => <NavigationLink key={item.url} {...item} />)}
    </LinkContainer>
    {subLevelNavigation[subItemKey] && <BottomLinkContainer style={{paddingTop: '4px'}}>
      {subLevelNavigation[subItemKey].map((item) => <NavigationLink key={item.url} {...item} />)}
    </BottomLinkContainer>}
  </NavigationContainer>;
}

export default Navigation;
