type Theme = {
  black: string;
  grey: string;
  dark_grey: string;
  light_grey: string;
  signature: string;
  dark_signature: string;
  counter_signature: string;
  white: string;
}

const theme: Theme = {
  counter_signature: "#FFBF50",
  grey: "#ACACAC",
  dark_grey: "#707070",
  light_grey: "#F4F4F4",
  signature: "#EA2190",
  dark_signature: "#BE327E",
  black: '#000000',
  white: '#FFFFFF'
}

export default theme;