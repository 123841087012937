import React, {FunctionComponent} from "react";
import {MagazineBrief} from "../../domain/content-response";
import Brief from '../brief';

type MagazineBriefProps = {
  brief: MagazineBrief;
}

const MagazineBrief: FunctionComponent<MagazineBriefProps> = ({brief}) => {
  const {magazineTitle, publishDate, magazineCover, magazineLink} = brief;

  if (!magazineLink) return null;

  const {_url} = magazineLink;

  return <Brief title={magazineTitle} subtitle={publishDate} image={magazineCover} url={_url} />;

};

export default MagazineBrief;
