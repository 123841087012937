import React, {FunctionComponent} from "react";
import styled from 'styled-components';
import {ImageMedia} from "../domain/content-response";
import {StyledLink} from "./typography/typography";
import {SecondaryButtonLink} from "./button/button";


type BriefProps = {
  image?: ImageMedia;
  title: string;
  subtitle?: string;
  subContent?: string;
  description?: string;
  url?: string;
}

const EventTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  font-family: Staatliches, sans-serif;
  background-color: ${({theme}) => theme.signature};
  color: white;
  font-size: 32px;
  padding: 4px 18px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  width: 100%;
`;

const EventSubTitle = styled.div`
  background-color: ${({theme}) => theme.dark_signature};
  color: white;
  display: inline-flex;
  flex-direction: column;
  padding: 12px 18px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  
  p {
    margin: 0;
  }

  @media all and (min-width: 968px) {
    float: right;
  }
`;

const EventRightColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  align-items: flex-end;
`;

const EventImageContainer = styled.div`
  width: 100%;
  height: 400px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const TitleOffset = styled.div`
  position: relative;
  width: 100%;
  z-index: 10;
  
  @media all and (min-width: 968px) {
    right: -60px;  
  }
`;

const EventDescription = styled.div`  
  font-size: 14px;
  margin-top: 24px;
  
  @media all and (min-width: 968px) {
    text-align: left;
    width: 85%;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const Brief: FunctionComponent<BriefProps> = (props) => {
  const {image, title, subtitle, subContent, description, url} = props;

  return <div className="container mb-5">
    <div className="row">
      <EventRightColumn className="col-md-6">
        <TitleOffset>
          <EventTitle>
            {title}
          </EventTitle>
          <EventSubTitle>
            <span>{subtitle}</span>
            <div dangerouslySetInnerHTML={{__html: subContent}} />
          </EventSubTitle>
        </TitleOffset>
        <EventDescription dangerouslySetInnerHTML={{__html: description}} />
      </EventRightColumn>

      <div className="col-md-6">
        {url && <a href={url}>
            <EventImageContainer style={{backgroundImage: `url("${image?._url}")`}} />
        </a>}

        {!url && <EventImageContainer style={{backgroundImage: `url("${image?._url}")`}} />}
      </div>
    </div>

    <div className="row mt-4">
      <div className="col-md-12">
        {url && <ButtonContainer>
          <SecondaryButtonLink to={url}>Læs mere</SecondaryButtonLink>
        </ButtonContainer>}
      </div>
    </div>
  </div>;

};

export default Brief;