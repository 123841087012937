import React, {FunctionComponent} from "react";
import styled from 'styled-components';

const AlternateSegmentContainer = styled.div`
  background-color: ${({ theme }) => theme.light_grey};
  padding: 40px 0;
  margin: 80px 0;  
`;

const AlternateSegment: FunctionComponent<{className: string}> = ({children, className}) => {
  return <AlternateSegmentContainer className={className}>
    <div className="container">
      {children}
    </div>
  </AlternateSegmentContainer>;
};

export default AlternateSegment;


