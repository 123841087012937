import React from "react";

function Email() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <g data-name="Group 19" transform="translate(-519 -3457)">
        <circle
          cx="12.5"
          cy="12.5"
          r="12.5"
          data-name="Ellipse 16"
          transform="translate(519 3457)"
        ></circle>
        <path
          fill="#fff"
          d="M15.676 65.6l-2.37 11.176c-.179.789-.645.985-1.308.614l-3.611-2.663L6.645 76.4a.907.907 0 01-.726.354l.259-3.678 6.692-6.047c.291-.259-.063-.4-.452-.144L4.146 72.1.584 70.983c-.775-.242-.789-.775.161-1.146l13.932-5.367c.645-.242 1.209.143.999 1.13z"
          transform="translate(522.633 3398.516)"
        ></path>
      </g>
    </svg>
  );
}

export default Email;
