import React, {FunctionComponent} from "react";
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const modalRoot = document.getElementById('modal-root');

const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 200;
`;

const Overlay: FunctionComponent<{onClick: () => void}> = ({onClick, children}) => {
  return ReactDOM.createPortal(
    <OverlayContainer onClick={onClick}>
      {children}
    </OverlayContainer>, modalRoot);
};

export default Overlay;