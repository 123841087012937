import React, {FunctionComponent} from "react";
import styled from 'styled-components';

type AvatarProps = {
  url?: string;
}

const AvatarContainer = styled.div`
  width: 200px;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  display: inline-flex;
  background-color: ${({theme}) => theme.light_grey};
  border: 2px solid ${({theme}) => theme.signature};
`;

const Avatar: FunctionComponent<AvatarProps> = ({url}) => {
  return <AvatarContainer style={{backgroundImage: `url("${url}")`}} />
};

export default Avatar;