import React, {FunctionComponent} from "react";
import styled from 'styled-components';
import {Bold} from "../typography/typography";
import {Link} from 'react-router-dom';

type ArticleBarProps = {
  title: string;
  url: string;
}

const ArticleContainer = styled(Link)`
  padding: 20px 40px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-left: 3px solid ${({theme}) => theme.signature};
  background-color: white;
  color: ${({theme}) => theme.black};
  margin-bottom: 16px;
  
  &:hover {
    color: ${({theme}) => theme.signature};
    text-decoration: none;
  }
`;

const ArticleBar: FunctionComponent<ArticleBarProps> = ({title, url}) => (
  <ArticleContainer to={url}>
    <Bold>{title}</Bold>
    <span>Læs mere</span>
  </ArticleContainer>
);

export default ArticleBar;