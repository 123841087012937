import React, {FunctionComponent} from 'react';
import styled, {css} from "styled-components";
import { Link } from 'react-router-dom';

export const Title = styled.h1`
  font-size: 64px;
  font-family: Staatliches, cursive;
`;

export const Header = styled.h2`
  font-size: 32px;
  font-family: Staatliches, cursive;
`;

export const SubTitle = styled.h3`
  font-size: 22px;
  text-transform: uppercase;
  font-family: Staatliches, cursive;
`;

export const Bold = styled.h3`
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
`;

export const SubHeader = styled.h3`
  font-size: 32px;
  color: ${({theme}) => theme.signature};
  font-family: 'Inconsolata', monospace;
  padding-bottom: 18px;
`;

export const CondensedSubHeader = styled(SubHeader)`
  font-size: 14px;
  color: ${({theme}) => theme.dark_grey};
`;

export const Content = styled.p`
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
`;

export const ContentContainer = styled.div`
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
`;

export const StyledLink = styled(Link)`
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  color: ${({ theme }) => theme.white};
  margin: 0 16px;
  opacity: .9;
  transition: opacity .4s;

  &:hover {
    color: inherit;
    text-decoration: none;
    opacity: 1;
  }
`;

export const StyledNativeLink = styled.a`
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  color: ${({ theme }) => theme.black};
  margin: 0;
  opacity: .9;
  transition: opacity .4s;

  &:hover {
    color: ${({ theme }) => theme.signature};
    text-decoration: none;
    opacity: 1;
  }
`;

const ButtonLink: FunctionComponent<{url: string; className: string}> = ({className, url, children}) => (
  <Link to={url} className={className}>
    {children}

    <svg
      style={{marginLeft: '8px'}}
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
    >
      <g data-name="Group 17" transform="translate(-86)">
        <circle
          cx="22"
          cy="22"
          r="22"
          fill="#fff"
          data-name="Ellipse 12"
          transform="rotate(-180 65 22)"
        ></circle>
        <path
          fill="#d43b8e"
          d="M10.449 0L20.9 12.384H0z"
          data-name="Polygon 1"
          transform="rotate(90 52.246 64.244)"
        ></path>
      </g>
    </svg>

  </Link>
)

export const ButtonStyleLink = styled(ButtonLink)`
  padding-left: 16px;
  border-radius: 32px;
  font-size: 12px;
  font-family: 'Inconsolata', monospace;
  color: ${({theme}) => theme.white};
  background: rgb(212,59,142);
  background: linear-gradient(180deg, rgba(212,59,142,1) 0%, rgba(137,39,92,1) 100%);
  &:hover {
    color: ${({theme}) => theme.white}; 
    text-decoration: none;
  }    
`;