import React from "react";

function LargePlane() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="206"
      height="206"
      viewBox="0 0 206 206"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#d43b8e"></stop>
          <stop offset="1" stopColor="#f4c164"></stop>
        </linearGradient>
      </defs>
      <g data-name="Group 22" transform="translate(-857 -472)">
        <circle
          cx="103"
          cy="103"
          r="103"
          fill="url(#linear-gradient)"
          data-name="Ellipse 23"
          transform="translate(857 472)"
        ></circle>
        <path
          fill="#fff"
          d="M99.513 72.017l-15.058 71.012c-1.136 5.012-4.1 6.259-8.308 3.9L53.2 130.02l-11.07 10.647c-1.225 1.225-2.25 2.25-4.611 2.25l1.648-23.366 42.526-38.423c1.849-1.648-.4-2.562-2.873-.913l-52.568 33.1-22.631-7.084c-4.923-1.537-5.012-4.923 1.025-7.284l88.519-34.1c4.098-1.539 7.685.911 6.348 7.17z"
          transform="translate(900.964 472.733)"
        ></path>
      </g>
    </svg>
  );
}

export default LargePlane;
