import React from "react";

function Link() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#d53e8d"></stop>
          <stop offset="1" stopColor="#f0ae6a"></stop>
        </linearGradient>
      </defs>
      <g data-name="Group 20" transform="translate(-1201 -3045)">
        <circle
          cx="12.5"
          cy="12.5"
          r="12.5"
          fill="url(#linear-gradient)"
          data-name="Ellipse 25"
          transform="translate(1201 3045)"
        ></circle>
        <path
          fill="#fff"
          d="M9.268 5.26a4.311 4.311 0 01.01 6.089l-.01.01-1.907 1.908a4.313 4.313 0 01-6.1-6.1l1.053-1.053a.454.454 0 01.775.3 5.229 5.229 0 00.275 1.5.457.457 0 01-.107.471l-.371.371a2.043 2.043 0 102.871 2.908l1.905-1.909a2.043 2.043 0 000-2.89 2.126 2.126 0 00-.293-.243.455.455 0 01-.2-.358 1.13 1.13 0 01.331-.845l.6-.6a.456.456 0 01.584-.049 4.327 4.327 0 01.582.488zm4-4a4.318 4.318 0 00-6.1 0L5.26 3.168l-.01.01a4.314 4.314 0 00.593 6.577.456.456 0 00.584-.049l.6-.6a1.13 1.13 0 00.332-.846.455.455 0 00-.2-.358 2.126 2.126 0 01-.293-.243 2.043 2.043 0 010-2.89l1.906-1.903a2.043 2.043 0 112.871 2.908l-.371.371a.457.457 0 00-.107.471 5.229 5.229 0 01.275 1.5.454.454 0 00.775.3l1.053-1.053a4.318 4.318 0 000-6.1z"
          transform="translate(1206.236 3050.236)"
        ></path>
      </g>
    </svg>
  );
}

export default Link;
