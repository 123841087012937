import React from "react";

function Plane({className}: {className:string}) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="88"
      height="88"
      viewBox="0 0 88 88"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#d43b8e"></stop>
          <stop offset="1" stopColor="#f4c164"></stop>
        </linearGradient>
      </defs>
      <g data-name="Group 21" transform="translate(-1184 -543)">
        <circle
          cx="44"
          cy="44"
          r="44"
          fill="url(#linear-gradient)"
          data-name="Ellipse 23"
          transform="translate(1184 543)"
        ></circle>
        <path
          fill="#fff"
          d="M42.6 67.66l-6.445 30.4c-.486 2.145-1.754 2.679-3.556 1.668l-9.82-7.236-4.739 4.557a2.466 2.466 0 01-1.974.963l.706-10 18.2-16.447c.791-.706-.172-1.1-1.23-.391l-22.5 14.168L1.559 82.3c-2.107-.653-2.145-2.1.441-3.113l37.889-14.6c1.752-.655 3.287.394 2.711 3.073z"
          transform="translate(1202.809 506.479)"
        ></path>
      </g>
    </svg>
  );
}

export default Plane;
