import React, {FunctionComponent, useContext} from "react";
import {MagazineArticles} from "../../domain/content-response";
import PageProps from "../../domain/page-props";
import {SubHeader} from "../typography/typography";
import {ContentContext, ContentContextState} from "../../app";
import ArticleBar from "./article-bar";

const Magazine: FunctionComponent<PageProps<MagazineArticles>> = ({content}) => {
  const {magazineTitle, magazineDescription, _id} = content;
  const {articles} = useContext<ContentContextState>(ContentContext);
  const pageArticles = articles[_id];

  return <>
    <div className="container mb-5">
      <div className="row mb-5">
        <div className="col-md-12 animate__animated animate__fadeIn">
          <SubHeader>{magazineTitle}</SubHeader>
          <div dangerouslySetInnerHTML={{__html: magazineDescription}}/>
        </div>
      </div>

      {pageArticles && <div className={'animate__animated animate__fadeIn animate__delay-1s'}>
        {pageArticles.map((article) => (
          <div className={'row'} key={article._id}>
            <ArticleBar title={article.name} url={article._url} />
          </div>
        ))}
      </div>}
    </div>
  </>;
};

export default Magazine;