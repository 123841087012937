import React, {FunctionComponent, Reducer, useEffect, useMemo, useReducer, useState} from 'react';
import theme from './theme';
import {ThemeProvider} from "styled-components";
import axios from 'axios';
import ContentResponse, {
  ContentItem,
  MagazineBrief,
  MagazineContent,
  NetworkContent
} from "./domain/content-response";
import View from './view';
import Member from "./domain/member";
import {useLocation} from "react-router-dom";
import _ from 'lodash';

export type NavigationItem = {
  url: string;
  text: string;
  parent?: string;
}

type ContentDispatchAction = { type: 'add-content', content: ContentResponse }
  | { type: 'add-descendants', content: ContentResponse }
  | { type: 'add-members', content: Member[], networkAPIKey: string }
  | { type: 'add-articles', content: ContentItem[] };

export type ContentContextState = {
  content: ContentItem[];
  labels: Record<string, ContentItem>;
  topLevelNavigation: NavigationItem[];
  networkNavigation: NavigationItem[];
  subLevelNavigation: Record<string, NavigationItem[]>;
  members: Record<string, Member[]>;
  articles: Record<string, ContentItem[]>;
  dispatch: (action: ContentDispatchAction) => void;
}

const defaultState: ContentContextState = {
  content: [],
  labels: {},
  topLevelNavigation: [],
  networkNavigation: [],
  subLevelNavigation: {},
  members: {},
  articles: {},
  dispatch: _.identity
};

export const ContentContext = React.createContext<ContentContextState>(defaultState);

const addContent = (content: ContentItem[], starter: Record<string, ContentItem>): Record<string, ContentItem> => {
  return content.reduce<Record<string, ContentItem>>((result, current) => {
    return {...result, [current._url]: current};
  }, starter);
}

function reducer(state: ContentContextState, action: ContentDispatchAction): ContentContextState {
  if (action.type === "add-content") {
    const {_embedded: {content}} = action.content;

    let labels = addContent(content, {});
    content.forEach((current) => {
      if (current.contentTypeAlias === "magazines") {
        const {briefs} = current as MagazineContent;
        labels = addContent(briefs.filter((brief) => Boolean(brief.magazineLink)).map((brief) => brief.magazineLink), labels);
      }
    });

    return {
      ...state,
      content,
      topLevelNavigation: content.filter((item) => item.contentTypeAlias !== 'global')
        .map<NavigationItem>((item) => ({url: item._url, text: item.name})),
      labels
    };
  }

  if (action.type === 'add-descendants') {
    const {labels} = state;
    const {_embedded: {content}} = action.content;
    const networkItems = content.filter((c) => c.contentTypeAlias === "network");
    const subLevelNavigation = networkItems.reduce((result, current) => {
      return {
        ...result,
        [current._url]: content
          .filter((c) => c.parentId === current._id)
          .map((c) => ({url: c._url, text: c.name}))
      };
    }, {});

    return {
      ...state,
      labels: addContent(content, labels),
      networkNavigation: networkItems.map((item) => ({url: item._url, text: item.name})),
      subLevelNavigation
    };
  }

  if (action.type === 'add-members') {
    const {content, networkAPIKey} = action;
    return {
      ...state,
      members: {
        ...state.members,
        [networkAPIKey]: content.sort((a, b) => {
          return a.name < b.name ? -1 : 1;
        })
      }
    }
  }

  if (action.type === "add-articles") {
    const {content} = action;
    const {labels, articles} = state;
    const parentId = content[0].parentId;

    return {
      ...state,
      labels: addContent(content, labels),
      articles: {
        ...articles,
        [parentId]: content
      }
    }
  }

  return state;
}
const pushState = history.pushState;
export const BASE_APP = 'https://eyeheight.herokuapp.com';
export const NEW_ENDPOINT = 'https://getnetworkusersv2-dreybpvreq-lz.a.run.app';
const membersUrl = `${NEW_ENDPOINT}?networkId=`;
//const membersUrl = `${BASE_APP}/get-members/`;
const App: FunctionComponent = () => {
  const location = useLocation();
  const [isLoadingContent, setIsLoadingContent] = useState<boolean>(true);
  const [state, dispatch] = useReducer<Reducer<ContentContextState, ContentDispatchAction>>(reducer, defaultState);
  const {content} = state;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    history.pushState = function (event, arg, target) {
      pushState.apply(history, arguments);
      window.scrollTo(0,0);

      gtag('config', 'G-4ME2KSRRKX', {
        'page_path': target
      });
    };

    return function () {
      history.pushState = pushState;
    }
  }, []);

  useEffect(() => {
    axios.get<ContentResponse>('https://cdn.umbraco.io/content', {
      headers: {
        'Accept-Language': 'da-DK',
        'umb-project-alias': 'eyeheight-hc'
      }
    })
      .then((result) => {
        const {data} = result;
        dispatch({type: 'add-content', content: data});
        setIsLoadingContent(false);

        const magazines = data._embedded.content.filter((c) => c.contentTypeAlias === "magazines")
          .reduce<Array<MagazineBrief>>((result, current) => {
            const {briefs} = current as MagazineContent;
            return [...result, ...briefs];
          }, []);

        if (magazines) {
          Promise.all(magazines.filter(({ magazineLink }) => Boolean(magazineLink)).map(({magazineLink: {_id}}) => {
            return axios.get<ContentResponse>(`https://cdn.umbraco.io/content/${_id}/children?pageSize=100`, {
              headers: {
                'Accept-Language': 'da-DK',
                'umb-project-alias': 'eyeheight-hc'
              }
            })
          })).then((result) => {
            result.forEach((response) => {
              const {data} = response;
              const {_embedded: {content}} = data;
              dispatch({ type: 'add-articles', content });
            });
          });
        }
      });
  }, []);

  useEffect(() => {
    if (!isLoadingContent) {
      const root = content.find((c) => c.contentTypeAlias === 'landing');
      if (root) {
        axios.get<ContentResponse>(`https://cdn.umbraco.io/content/${root._id}/descendants`, {
          headers: {
            'Accept-Language': 'da-DK',
            'umb-project-alias': 'eyeheight-hc'
          }
        })
          .then((result) => {
            const {data} = result;
            const {_embedded: {content}} = data;
            const networks = content.filter((d) => d.contentTypeAlias === "network");

            for (let i = 0; i < networks.length; i++) {
              const {networkAPIKey} = networks[i] as NetworkContent;
              axios.get<{ users: Member[] }>(`${membersUrl}${networkAPIKey}`, { headers: { 'Authorization': 'Bearer PrN8C6fZtNOHXMrEiU9V'}})
                .then((response) => {
                  const {data} = response;

                  dispatch({type: 'add-members', content: data.users, networkAPIKey});
                });
            }

            dispatch({type: 'add-descendants', content: data});
          });
      }
    }
  }, [isLoadingContent]);

  const context = useMemo<ContentContextState>(() => {
    return {
      ...state,
      dispatch
    }
  }, [state]);

  return <ThemeProvider theme={theme}>
    <ContentContext.Provider value={context}>
      <View/>
    </ContentContext.Provider>
  </ThemeProvider>;
};

export default App;
