import React, {FunctionComponent} from "react";
import {MetaData} from "../../domain/content-response";
import {Helmet} from "react-helmet";

type MetaDataProps = {
  metaData: MetaData;
}

const MetaData: FunctionComponent<MetaDataProps> = ({metaData}) => {
  const {metaTitle, metaDescription} = metaData;

  return <Helmet>
    <title>{metaTitle}</title>
    <meta name="description" content={metaDescription} />
  </Helmet>;

};

export default MetaData;