import React, {FunctionComponent} from 'react';
import {EventContent} from "../../domain/content-response";
import Brief from "../brief";

type EventProps = {
  event: EventContent;
}

const Event: FunctionComponent<EventProps> = ({event}) => {
  const {eventTitle, eventImage, eventTime, eventLocation, eventDescription} = event;

  return <Brief
    title={eventTitle}
    subtitle={eventTime}
    subContent={eventLocation}
    description={eventDescription}
    image={eventImage}
  />

};

export default Event;